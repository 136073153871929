import React from 'react'
import {
  SideMenuContainer,
  LogoContainer,
  CartContainer,
  NavigationLinks,
} from './NavbarComponents'
import { Navbar } from '../index'
import { Box } from '@mui/system'

export function DefaultNavbar({ leftComponent = defaultLeftComponent }) {
  return (
    <Navbar
      leftComponent={leftComponent}
      centerComponent={<LogoContainer />}
      rightComponent={<CartContainer />}
    />
  )
}

export const ClientSideComponentSwitcher = ({ OnMobile, OnDesktop }) => (
  <>
    <Box sx={{ display: { xs: 'block', lg: 'none' } }}>
      <OnMobile />
    </Box>
    <Box sx={{ display: { xs: 'none', lg: 'block' } }}>
      <OnDesktop />
    </Box>
  </>
)

const defaultLeftComponent = (
  <ClientSideComponentSwitcher
    OnMobile={SideMenuContainer}
    OnDesktop={NavigationLinks}
  />
)
