import React from 'react'
import analyticsManager from '../../../../../services/analytics/analyticsManager'
import EVENT_NAMES from '../../../../../consts/eventNames'
import { Arrow, ARROW_DIRECTIONS } from '../../../../Arrow/Arrow'
import { useRouter } from 'next/router'
import { SessionStorage } from '@lib/shopify/storefront-data-hooks/src/utils/SessionStorage'
import {
  getRouteURL,
  PAGES,
  PRODUCT_PAGE_BACK_BUTTON_STRATEGIES,
} from '../../../../../consts/pages'
import { useQueryState } from 'hooks/useQueryState'
import useSafeRouterPush from 'hooks/useSafeRouterPush'

export const getProductHandleFromURL = (url) => {
  let productHandle = url.split('/').reverse()[0].split('?')[0]

  // We want to remove in # in case we have those in our URL
  if (productHandle.includes('#')) {
    productHandle = productHandle.split('#')[0]
  }

  return productHandle
}

function BackButtonContainer() {
  const router = useRouter()
  const safeRouterPush = useSafeRouterPush()
  const [queryParams, _] = useQueryState()

  const backStrategy = {
    [PRODUCT_PAGE_BACK_BUTTON_STRATEGIES.DEFAULT]: () => {
      if (window.history.length === 1) {
        const [_productHandle, ...url] = router.asPath.split('/').reverse()
        const collectionsPageURL = url.reverse().join('/')
        safeRouterPush(collectionsPageURL)
      } else {
        router.back()
      }
    },
    [PRODUCT_PAGE_BACK_BUTTON_STRATEGIES.PRODUCT_FEED]: () => {
      const [_productHandle, ...url] = router.asPath.split('/').reverse()
      const collectionsPageURL = url.reverse().join('/')
      const orderOnPage = queryParams.orderOnPage

      safeRouterPush(`${collectionsPageURL}?orderOnPage=${orderOnPage}`)
    },
    [PRODUCT_PAGE_BACK_BUTTON_STRATEGIES.PLANT_CARE]: () => {
      const productHandle = SessionStorage.getLastVisitedPlantHandle()

      if (!productHandle) {
        return backStrategy[PRODUCT_PAGE_BACK_BUTTON_STRATEGIES.DEFAULT]()
      }

      safeRouterPush(
        `${getRouteURL(PAGES.CARE_PLANT)}/${productHandle}#products`
      )
    },
  }

  const goBack = () => {
    const { from } = router.query

    analyticsManager.track(EVENT_NAMES.HEADER_BACK_CLICKED)

    try {
      // @ts-ignore
      backStrategy[from || PRODUCT_PAGE_BACK_BUTTON_STRATEGIES.DEFAULT]()
    } catch (e) {
      console.error('Product Page Back button error: ', e)
    }
  }

  return <Arrow direction={ARROW_DIRECTIONS.LEFT} onClick={goBack} />
}

export default BackButtonContainer
