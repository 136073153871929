import {
  CloudinaryTransformationsParams,
  GetCloudinaryURLParams,
} from './cloudinaryService.types'

const CLOUDINARY_BASE_URL = 'https://res.cloudinary.com/easyplant'
const CLOUDINARY_IMAGE_BASE_URL = `${CLOUDINARY_BASE_URL}/image`
const CLOUDINARY_VIDEO_BASE_URL = `${CLOUDINARY_BASE_URL}/video`
const CLOUDINARY_BASE_UPLOAD_URL = `${CLOUDINARY_IMAGE_BASE_URL}/upload`
const CLOUDINARY_BASE_VIDEO_UPLOAD_URL = `${CLOUDINARY_VIDEO_BASE_URL}/upload`
const CLOUDINARY_BASE_FETCH_URL = `${CLOUDINARY_IMAGE_BASE_URL}/fetch`

export const getOptimizedPublicURL = getFolderURL('public')
export const getOptimizedHomePageURL = getFolderURL('homepage2')
export const getHomepageVideosPublicURL = getFolderURL(
  'homepage2',
  CLOUDINARY_BASE_VIDEO_UPLOAD_URL
)
export const getOptimizedPlantCareURL = getFolderURL('care')
export const getB2bPublicURL = getFolderURL('b2b')
export const getGiftingPublicURL = getFolderURL('gifting')
export const getStandsPublicURL = getFolderURL('stands')
export const getStandsVideosPublicURL = getFolderURL(
  'stands',
  CLOUDINARY_BASE_VIDEO_UPLOAD_URL
)

export const getProductPagePublicURL = getFolderURL('product_page')
export const getProductPageVideosPublicURL = getFolderURL(
  'product_page',
  CLOUDINARY_BASE_VIDEO_UPLOAD_URL
)
export const getColorsPalettesImagesPublicURL = getFolderURL('color palettes')
export const getRetailPublicURL = getFolderURL('retails')

function getFolderURL(folderName, baseUrl = CLOUDINARY_BASE_UPLOAD_URL) {
  return function (
    plant: string,
    { width, height }: CloudinaryTransformationsParams = {},
    suffix: string = 'jpg'
  ): string {
    const transformations = getTransformations({ width, height })
    return `${baseUrl}/${transformations}/${folderName}/${plant}.${suffix}`
  }
}

/*
Cloudinary caches images for 7 days by default and then checks the etag to see if the images needs to be replaced
We can change the cache time if we'd like.
For more information:
https://cloudinary.com/documentation/fetch_remote_images
 */
export function getCloudinaryFetchURL({
  url,
  width,
  height,
  q = 'auto',
}: GetCloudinaryURLParams): string {
  const transformations = getTransformations({ width, height, q })
  return `${CLOUDINARY_BASE_FETCH_URL}/${transformations}/${url}`
}

const getDevicePixelRatio = () => {
  const defaultRatio = 1

  if (typeof window !== 'undefined')
    return window.devicePixelRatio || defaultRatio

  return defaultRatio
}

function getTransformations({
  width,
  height,
  q = 'auto',
}: CloudinaryTransformationsParams) {
  // (default) q=60 good quality; best size
  // q=auto best quality; ok size
  const transformations = ['f_auto', `q_${q}`, 'c_fill']

  if (width) {
    transformations.push(`w_${width * Math.ceil(getDevicePixelRatio())}`)
  }

  if (height) {
    transformations.push(`h_${height * Math.ceil(getDevicePixelRatio())}`)
  }

  return transformations.join(',')
}
