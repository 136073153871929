import { Router, useRouter } from 'next/router'
import { useEffect, useRef } from 'react'
import NavigationService, {
  NextRoutingThrottleType,
} from '../services/navigationService'

const useSafeRouterPush = () => {
  const pathInProgress = useRef<string | null>(null)
  const handleRouteChange = () => {
    pathInProgress.current = null
  }
  const router = useRouter()

  // safePush is used to avoid route pushing errors when users click multiple times or when the network is slow:  "Error: Abort fetching component for route"
  const safePush: Router['push'] = (path: string, as, options) => {
    if (isInAppURL(path)) {
      // Throttle navigation only for in-app navigation
      if (pathInProgress.current === path) {
        NavigationService.trackNextRoutingThrottled(
          NextRoutingThrottleType.IgnoreRepeatedNavigation,
          path
        )
        return
      }
      if (router.asPath === path) {
        NavigationService.trackNextRoutingThrottled(
          NextRoutingThrottleType.IgnoreSamePageNavigation,
          path
        )
        return
      }
      pathInProgress.current = path
    }
    return (
      router
        .push(path, as, options)
        // Taken from: https://github.com/vercel/next.js/issues/37362#issuecomment-1283671326
        .catch((e) => {
          if (!e.cancelled) {
            throw e
          }
          return false
        })
    )
  }

  useEffect(() => {
    return NavigationService.subscribeToPageChanges(handleRouteChange)
  }, [])

  return safePush
}

function isInAppURL(url: string) {
  try {
    new URL(url) // in-app urls are relative and so cannot be parsed as a full url
    return false
  } catch (e) {
    return true
  }
}

export default useSafeRouterPush
