import React, { ButtonHTMLAttributes } from 'react'
import { CircularProgress, styled } from '@mui/material'

type StyledButtonProps = {
  height: number
}

export type ButtonProps = ButtonHTMLAttributes<HTMLButtonElement> & {
  height?: number
  loading?: boolean
  children: React.ReactNode
}

const StyledButton = styled('button')<StyledButtonProps>`
  width: 100%;
  background: ${({ color, theme }) => color || theme.colors2.green300};
  color: white;
  font-family: Fraunces, serif;
  text-align: center;
  border: 0;
  outline: 0;
  border-radius: 4px;
  font-size: 18px;
  font-weight: 600;
  height: ${({ height }) => height}px;
  cursor: pointer;

  &:hover {
    background-image: -webkit-gradient(
      linear,
      left top,
      left bottom,
      from(hsla(0, 0%, 100%, 0.1)),
      to(hsla(0, 0%, 100%, 0.1))
    );
    background-image: linear-gradient(
      180deg,
      hsla(0, 0%, 100%, 0.1),
      hsla(0, 0%, 100%, 0.1)
    );
    box-shadow: 0 3px 15px 1px rgba(0, 0, 0, 0.09);
  }
`

const Button = ({ loading, height, children, ...buttonProps }: ButtonProps) => {
  return (
    <StyledButton height={height} disabled={!!loading} {...buttonProps}>
      {loading ? <CircularProgress size={20} color={'inherit'} /> : children}
    </StyledButton>
  )
}

export default Button

export const AddToCartButton = styled(Button)<StyledButtonProps>`
  font-weight: 500;
`
