import styled, { keyframes } from 'styled-components'

const slideUp = keyframes`
  0% {
    transform: translateY(70px);
  }
  100% {
    transform: translateY(0);
  }
`

export const FloatingButtonWrapper = styled('div')`
  position: fixed;
  z-index: 5;
  bottom: 0;
  left: 0;
  width: 100%;
  background: ${({ theme }) => theme.colors.cream3};
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 14px;
  border-top: 1px solid ${({ theme }) => theme.colors.cream};
  box-shadow: 0px -4px 4px 0px rgba(0, 0, 0, 0.05);

  &.slideUpAnimation {
    animation: ${slideUp} 500ms forwards;
  }
`
