import React, { useEffect, useState } from 'react'
import * as S from './CartContainer.styles'
import CartLogo from '@components/icons/Cart.svg'
import { useUI } from '@components/ui/context'
import { useCartCount } from '@lib/shopify/storefront-data-hooks'
import analyticsManager from 'services/analytics/analyticsManager'
import EVENT_NAMES from 'consts/eventNames'

function itemCountLabel(count) {
  if (count === 0) {
    return 'no items in the Cart'
  }
  if (count > 99) {
    return 'more than 99 items in the Cart'
  }
  return `${count} items in the cart`
}

const CartContainer = () => {
  const { openCart } = useUI()
  const itemCount = useCartCount()
  const [cartLabel, setCartLabel] = useState(0)

  useEffect(() => {
    setCartLabel(itemCount > 99 ? 99 : itemCount)
  }, [itemCount])

  const onOpenCart = () => {
    analyticsManager.track(EVENT_NAMES.CART_ICON_CLICKED)
    openCart()
  }

  return (
    <S.CartContainer onClick={onOpenCart}>
      <S.CartIconContainer aria-label={itemCountLabel(itemCount)}>
        {cartLabel > 0 && (
          <S.CartBadge $itemsCounts={itemCount}>{cartLabel}</S.CartBadge>
        )}
        <CartLogo width={24} height={24} />
      </S.CartIconContainer>
    </S.CartContainer>
  )
}

export default CartContainer
