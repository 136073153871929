import Button from './Button2'
import * as S from './styles'

export const FloatingButton = (props) => {
  return (
    <S.FloatingButtonWrapper
      className={props.animate ? 'slideUpAnimation' : ''}
    >
      <Button data-has-fab='true' {...props} />
    </S.FloatingButtonWrapper>
  )
}
