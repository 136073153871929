import { useRouter } from 'next/router'
import { ParsedUrlQuery } from 'querystring'
import { useCallback } from 'react'

export const useQueryState = (): [
  ParsedUrlQuery,
  (query: ParsedUrlQuery) => void
] => {
  const router = useRouter()

  const setQueryParams = useCallback(
    (query: ParsedUrlQuery = {}) => {
      router
        .replace(
          { pathname: router.pathname, query: { ...router.query, ...query } },
          undefined,
          {
            shallow: true,
          }
        )
        // Taken from: https://github.com/vercel/next.js/issues/37362#issuecomment-1283671326
        .catch((e) => {
          if (!e.cancelled) {
            throw e
          }
        })
    },
    [router]
  )

  return [router.query, setQueryParams]
}
