import React from 'react'
import { Logo } from '../../../icons'
import EVENT_NAMES from '../../../../consts/eventNames'
import analyticsManager from '../../../../services/analytics/analyticsManager'
import StyledLink from '@components/common/StyledLink/StyledLink'
import { useTheme } from '@config/Theme/theme'

function LogoContainer() {
  const theme = useTheme()
  const logoStyle = {
    display: 'flex',
    color: theme.colors.green6,
  }

  const reportLogoClick = () => {
    analyticsManager.track(EVENT_NAMES.HEADER_LOGO_CLICKED)
  }

  return (
    <StyledLink
      style={logoStyle}
      href={'/'}
      onClick={reportLogoClick}
      aria-label='Navigate to the homepage'
    >
      <Logo />
    </StyledLink>
  )
}

export default LogoContainer
