import { Back } from '@components/icons'
import React from 'react'

export const ARROW_DIRECTIONS = {
  LEFT: 'left',
  RIGHT: 'right',
}

export function Arrow({
  direction = ARROW_DIRECTIONS.LEFT,
  className = '',
  style = {},
  onClick = () => {},
}) {
  const directionMultiplier = direction === ARROW_DIRECTIONS.RIGHT ? 1 : 0
  const directionStyle = {
    transform: `rotate(${180 * directionMultiplier}deg)`,
  }

  return (
    <div
      className={className}
      style={{ ...directionStyle, ...style }}
      onClick={onClick}
    >
      <Back />
    </div>
  )
}
