import { onLargeScreen } from '@config/Theme/theme'
import IconButton from '@mui/material/IconButton'
import { LAYOUT_DESKTOP_THRESHOLD } from '@utils/styleUtils'
import styled, { css } from 'styled-components'

export const CartContainer = styled('div')(({ theme }) => ({
  cursor: 'pointer',
  order: 1,
  width: 'auto',
  display: 'flex',
  ...onLargeScreen({
    marginLeft: '35px', // This pushes The Header Links to go more towards the center

    '&::before': {
      content: '"Cart"',
      color: theme.colors.green,
      display: 'flex',
      alignItems: 'center',
      paddingRight: '8px',
    },
  }),
}))

export const CartBadge = styled.div<{ $itemsCounts: number }>`
  border-radius: 50%;
  padding: 0;
  color: ${({ theme }) => theme.colors.white};
  height: 20px;
  min-width: 20px;
  background-color: ${({ theme }) => theme.colors.black3};

  display: flex;
  flex-flow: wrap;
  place-content: center;
  align-items: center;
  justify-content: center;

  position: absolute;
  z-index: 1;

  box-sizing: border-box;
  font-weight: 500;
  font-size: 0.75rem;
  transition: transform 225ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;

  transform: scale(1) translate(50%, 50%);
  transform-origin: 100% 100%;

  ${({ $itemsCounts }) =>
    $itemsCounts >= 10 &&
    css`
      height: 22px;
      min-width: 22px;
    `}

  @media only screen and (max-width: ${LAYOUT_DESKTOP_THRESHOLD}px) {
    font-size: 10px;
    height: 17px;
    min-width: 17px;
    ${({ $itemsCounts }) =>
      $itemsCounts >= 10 &&
      css`
        height: 19px;
        min-width: 19px;
      `}
  }
`

export const CartIconContainer = styled(IconButton)`
  padding: 0;
  transform: scaleX(1.091);
  flex-grow: 0;
`
