import React from 'react'
import IconButton from '@mui/material/IconButton'
import Hamburger from '../../../icons/Hamburger.svg'
import { useUI } from '../../../ui/context'
import analyticsManager from 'services/analytics/analyticsManager'
import EVENT_NAMES from 'consts/eventNames'

function SideMenuContainer() {
  const { openSideMenu } = useUI()

  const onSideMenuClick = () => {
    analyticsManager.track(EVENT_NAMES.MENU_ICON_CLICKED)
    openSideMenu()
  }

  return (
    <IconButton
      aria-label='Side Menu'
      onClick={onSideMenuClick}
      sx={{ padding: 0 }}
    >
      <Hamburger />
    </IconButton>
  )
}

export default SideMenuContainer
