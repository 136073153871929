import React, { useEffect, useState } from 'react'
import StyledLink from '@components/common/StyledLink/StyledLink'
import navigationLinks from '../../../../config/navigation.json'
import { useTheme } from '@config/Theme/theme'
import { useRouter } from 'next/router'
import { isCollectionsPage } from '@utils/runtimeUtils'
import { useDefaultCollectionsURL } from '../../../../hooks/useDefaultCollectionsURL'
import { isGiftingAccessViewer } from '@lib/utils/gifting-access/gifting-access-viewer'
import { useHomepageEmailCaptureButton } from 'hooks/useHomepageCTAButton'

let { navbar: originalNavbarLinks } = navigationLinks

function NavigationLinks() {
  const theme = useTheme()
  const router = useRouter()
  const { redirectToEmailCapture, emailCaptureLink } =
    useHomepageEmailCaptureButton()
  const shopPlantsLink = useDefaultCollectionsURL()
  const isCollectionPage = isCollectionsPage(router)

  const [navbarLinks, setNavbarLinks] = useState(originalNavbarLinks)

  navbarLinks[0].link = redirectToEmailCapture
    ? emailCaptureLink(shopPlantsLink)
    : shopPlantsLink

  useEffect(() => {
    const isGiftingViewer = isGiftingAccessViewer()
    const giftingAlreadyAddedToMenu = originalNavbarLinks.some(
      (x) => x.title === 'Gifting'
    )
    if (isGiftingViewer && !giftingAlreadyAddedToMenu) {
      originalNavbarLinks = [
        ...originalNavbarLinks.slice(0, 1),
        {
          link: '/gifting',
          title: 'Gifting',
        },
        ...originalNavbarLinks.slice(1),
      ]
      setNavbarLinks(originalNavbarLinks)
    }
  }, [])

  useEffect(() => {
    if (redirectToEmailCapture) {
      const newLinks = originalNavbarLinks.map(({ title, link }) => ({
        title,
        link: emailCaptureLink(link),
      }))
      setNavbarLinks(newLinks)
    } else {
      setNavbarLinks(originalNavbarLinks)
    }
  }, [redirectToEmailCapture])

  const linkStyle = {
    padding: '5px 10px',
    borderRadius: '20px',
    lineHeight: '135%',
    fontSize: '14px',
    color: isCollectionPage ? theme.colors.black3 : theme.colors.green,
    fontfamily: 'Fraunces',
    letterSpacing: '-0.28px',
  }

  return (
    <div>
      {navbarLinks.map((link, index) => (
        <StyledLink key={index} href={link.link} style={linkStyle}>
          {link.title}
        </StyledLink>
      ))}
    </div>
  )
}

export default NavigationLinks
